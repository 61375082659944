
import { useEffect, useState } from "react";
import ExpenseDetails from "../ExpenseDetails";
import NotFound from "../NotFound";
import TablePaginationFooter from "../TablePaginationFooter/TablePaginationFooter";
import { useAppSelector } from "../../app/hooks";
import { getAllInquiries } from "../../actions/adminActions";
import Table from "../Table";


const AllInquiry = (props) => {
  const {inquiry} = useAppSelector((state) => state.admin)
  const [expense, setExpense] = useState<Record<string, any>>({});
  const handleCloseDetail = () => {
    setExpense({});
  }

  const {setHideNav} = props;

  useEffect(() => {
    if (expense.id) {
      setHideNav(true);
    }
  }, [expense.id, setHideNav]);

  if (expense.id) {
    return <ExpenseDetails expense={expense} handleCloseDetail={handleCloseDetail} />;
  }
  const handlePagination = (payload) => {
    const page = payload.split('=')[1];
    getAllInquiries(page);
  }

  const header = [
    {field: 'first_name', headerName:  'Firstname'},
    {field: 'last_name', headerName:  'Lastname'},
    {field: 'phone_number', headerName:  'Phone number'},
    {field: 'email', headerName:  'Email'},
    {field: 'issue', headerName:  'Message'},
    {field: 'created_at', headerName: 'Created Date'}
  ];

  return (
        <div className="w-[100%]">
          <div className="!w-full">
            <div className="body-section !w-full h-[70vh] text-left">
              {(!inquiry || inquiry?.data?.length === 0) ?
                (
                  <NotFound 
                    text1="No Inquiry in the system yet!"
                    text2='Manage all inquiry sent in here.'
                    btnLabel=""
                    onClick={() => {}}
                  />
                ) :
                <Table
                  header={header}
                  data={inquiry?.data}
                  fitStrategy="fitCellContents"
                />
              }
            </div>
          </div>
            {
                inquiry?.data?.length !== 0 && (
                <TablePaginationFooter 
                    total={inquiry?.total}
                    from={inquiry?.from}
                    to={inquiry?.to}
                    nextUrl={inquiry?.next_page_url}
                    prevUrl={inquiry?.prev_page_url}
                    handlePagination={handlePagination}
                />
                )
            }
        </div>
  );
};

export default AllInquiry;