import { useNavigate, useLocation } from 'react-router-dom';
import Logo from '../../assets/Logo.svg';
import SettingsSVG from '../SVG/Settings';
import { handleLogout, handleSideNavToggle } from '../../actions/dashboardActions';
import './dashboardSideBar.scss';
import { useAppSelector } from '../../app/hooks';
import { useEffect, useState } from 'react';
import { appDispatch } from '../../app/store';
import { toggleSideNav } from '../../pages/Dashboard/dashboardSlice';
import { SwitchMode } from '../DashboardComponentWrapper';
import useVerificationDone from '../../hooks/useVerificationDone';
import useIsAdmin from '../../hooks/useIsAdmin';
import useShowNewFeature from '../../hooks/useShowNewFeature';

const DashboardSideBar = ({items, firstItem}) => {
  const showFeature = useShowNewFeature();
  const isAdmin = useIsAdmin();
  const {showNav} = useAppSelector((state) => state.dashboard);
  const {isVerificationDone} = useVerificationDone();
  const navigate = useNavigate();
  const location = useLocation().pathname.split("/");
  const [showToggleInfo, setShowToggleInfo] = useState<boolean>(false);

  useEffect(() => {
    const showInfo = localStorage.getItem('toggle-info');
    if (!showInfo) {
      setShowToggleInfo(true);
      localStorage.setItem('toggle-info', 'true');
    }
  }, []);


  const handleVerify = () => {
    navigate('/dashboard/profile');
  }

  return (
    <div className={`fixed lg:sticky top-0 left-0 bg-white w-[300px] side-nav overflow-auto ${showNav ? 'show-nav' : 'hide-nav'}`}>
      <div>
        <div className="logo-wrapper px-6 pb-10 flex flex-row justify-between items-center">
          <img src={Logo} alt="" className='h-14 object-contain' />
          <svg
            onClick={() => {
              handleSideNavToggle(!showNav);
              !localStorage.getItem('toggle-info') && localStorage.setItem('toggle-info', 'true');
            }}
            xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className='flex lg:hidden cursor-pointer'>
            <path d="M15.787 14.7579L9.02831 7.99554L15.787 1.23316C16.0593 0.951224 16.0593 0.504153 15.787 0.222124C15.508 -0.0669215 15.0476 -0.074916 14.7587 0.20427L7.99997 6.96665L1.24132 0.204359C0.959535 -0.0679875 0.512705 -0.0679875 0.230828 0.204359C-0.0580623 0.483545 -0.0660523 0.944206 0.212983 1.23325L6.97163 7.99554L0.212983 14.7578C0.0766172 14.8943 1.81872e-08 15.0793 1.81872e-08 15.2722C-8.88562e-05 15.6741 0.325557 15.9999 0.727198 16C0.920117 16.0002 1.10513 15.9234 1.24132 15.7867L7.99997 9.02443L14.7587 15.7868C14.8949 15.9235 15.08 16.0003 15.2729 16C15.4657 15.9999 15.6505 15.9233 15.7869 15.787C16.071 15.5028 16.0711 15.0421 15.787 14.7579Z" fill="#222222B2"/>
          </svg>
        </div>
        <ul className='[&>*:nth-child(2)]:mb-4 [&>*:nth-child(2)]:border-b-2 [&>*:nth-child(2)]:border-[#22222226]'>
          {
            items.map((navItem) => {
              const Icon = navItem.Icon;
              if (navItem.isNewFeature && showFeature || !navItem.isNewFeature) {
                return (
                  <li
                    key={navItem.name}
                    onClick={() => {
                      navigate(navItem.path);
                      if (window.innerWidth < 780) {
                        appDispatch(toggleSideNav(false));
                      }
                    }}
                    className={`
                    flex flex-row px-4 py-4 justify-start items-center my-3 cursor-pointer
                    ${
                      (navItem.title === 'Home' && location[2] === '') ||
                      location[2] === navItem.name ||
                      (navItem.title === firstItem && location.length === 2) ||
                      (navItem.title === firstItem && location.length === 4) ||
                      (location[4] && location[4] === navItem.name)
                        ? "selected"
                        : ""
                    }`}
                  >
                    {navItem.Icon ? <Icon /> : <img src={navItem.img} alt="" className='mr-4 fill-black' />}
                    <span className='text-sm text-[#FFFFFFB2]'>{navItem.title}</span>
                  </li>
                )
              }
              return null;
            })
          }
        </ul>
      </div>
      <div className="s-openner border-[#22222226] border-t w-10/12 self-center">
        {
          !isAdmin && (
            <div className='w-full h-10 flex flex-row justify-center items-center text-md'>
              {
                isVerificationDone ? <p className='py-2 w-full rounded-md text-white bg-green-700 text-center'>Verified</p> : <p onClick={handleVerify} className='py-3 cursor-pointer rounded-md w-full text-white bg-orange-700 text-center'>Pending verification</p>
              }
            </div>
          )
        }
        {
          !isAdmin && (
            <div className='w-full h-10  my-4 ml-3 !text-white flex justify-start items-center'>
              <SwitchMode />
            </div>
          ) 
        }
        <li className='flex flex-row justify-start items-center py-4 pl-2 !w-full h-10 cursor-pointer' onClick={() => handleLogout(navigate)}>
          {/* <img src={Logout} alt="" className='' /> */}
          <SettingsSVG />
          <span className='text-white'>Log Out</span>
        </li>
      </div>
    </div>
  );
}

export default DashboardSideBar;
