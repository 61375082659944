/* eslint-disable eqeqeq */
import "./issueDetails.scss";
import { useCallback } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { resolveFeedback } from "../../actions/authActions";
import { useAppSelector } from "../../app/hooks";
import { getAllComplains, resolveFeedbackAdmin } from "../../actions/adminActions";
dayjs.extend(relativeTime);


const ExpenseDetailsCard = ({
  id, 
  title, 
  description,
  issue,
}: {
  id: string,
  title: string,
  description: string,
  issue: Record<string, any>
}) => {
  const {user} = useAppSelector((state) => state.auth);

  const handleResolveFeedback = useCallback(() => {
    const handler = user?.usertype === 'admin' ? resolveFeedbackAdmin : resolveFeedback
    handler(issue.complain_reference_code, (resp) => {
      getAllComplains(1);
    });
  }, []);

  
  return (
    <div className="right-wrapper">
      <div className="flex flex-col w-full">
        <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">Date</p>
          <p className="text-sm text-az-black-900">{dayjs(issue.created_at).fromNow()}</p>
        </div>
        <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">Title</p>
          <p className="text-sm text-az-black-900">{title}</p>
        </div>
        <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">Severity</p>
          <p className="text-sm text-az-black-900 uppercase">{issue.severity}</p>
        </div>
        <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">Description</p>
          <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">{description}</p>
        </div>
        <div className="flex w-full flex-row justify-end items-center py-4">
          <div onClick={handleResolveFeedback} className="!border-2 border-az-teal rounded px-8 py-2 cursor-pointer">
            <span>Resolve</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExpenseDetailsCard