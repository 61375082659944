import { useCallback, useEffect, useState } from "react";
import {  refEnumsInvert } from ".";
import Button from "../../../components/Button";
import Input, { Select as SelectComponent } from "../../../components/Input";
import dayjs from "dayjs";
import { createReferral, updateReferral } from "../../../actions/adminActions";

export const refEnums = {
  'Fund Wallet': 'fund_wallet',
  'Evergreen': 'evergreen',
  'Fixed': 'fixed',
  'Charges deduction': 'charges_deduction',
  'Active': 'active',
  'Not active': 'not_active'
}

const pointSourceMap = {
  'Single Product': 'A_single_product',
  'All product': 'Across_All_products'
}

export const AdminCreateReferral = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState({
    // name: '',
    // description: '',
    status: '',
    point_limit: '0',
    start_date: '',
    duration: '',
    point_conversion: '',
    end_date: '',
    product_getting_point: '',
    referral_active_point: ''
  });

  useEffect(() => {
    if (props.referral) {
      setIsEdit(true);
      const { status, point_limit, start_date, end_date, duration, point_conversion, product_getting_point, referral_active_point } = props.referral;
      setData({
        status: refEnumsInvert[status],
        point_limit,
        start_date,
        duration: refEnumsInvert[duration],
        point_conversion: refEnumsInvert[point_conversion],
        product_getting_point: pointSourceMap[product_getting_point],
        end_date,
        referral_active_point,
      })
    }
  }, [props.referral]);

  const handleChange = useCallback((name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }, []);


  const handleAddFormSubmit = (event) => {
    event.preventDefault();
    const newData = {
      ...data,
      status: refEnums[data.status],
      duration: refEnums[data.duration],
      point_conversion: refEnums[data.point_conversion],
      product_getting_point: pointSourceMap[data.product_getting_point],
      start_date: dayjs(data.start_date).format('YYYY-MM-DD'),
      end_date: dayjs(data.end_date).format('YYYY-MM-DD')
    }
    if (data.duration === 'Evergreen') {
      // @ts-ignore
      delete newData.end_date;
    }
    if (isEdit) {
      updateReferral(props.referral.id, newData, (resp) => {
        props.handleDone(false);
      });
      return;
    }
    createReferral(newData, (resp) => {
      props.handleDone(false);
    });
  }

  return (
    <div className="w-full">
      <h1 className="text-3xl">{isEdit ? '' : 'Create Referral'}</h1>
      <form onSubmit={handleAddFormSubmit} className="">
        {/* <Input
                label="Name"
                placeholder="Referral bundle"
                name="name"
                value={data.name}
                onChange={handleChange}
              /> */}
        <Input
          label="Point limit"
          placeholder="5"
          name="point_limit"
          value={data.point_limit}
          onChange={handleChange}
        />
        <SelectComponent
          data={['Evergreen', 'Fixed']}
          label="Duration"
          placeholder="Evergreen"
          name="duration"
          required
          value={data.duration}
          onChange={handleChange}
        />
        <SelectComponent
          data={['Fund Wallet', 'Charges deduction']}
          label="Point conversion"
          placeholder="Fund wallet"
          name="point_conversion"
          value={data.point_conversion}
          onChange={handleChange}
        />
        <SelectComponent
          data={['Active', 'Not active']}
          label="Status"
          placeholder="Active"
          name="status"
          value={data.status}
          onChange={handleChange}
        />
        <Input
          label="Referral active point"
          placeholder="5"
          type="number"
          name="referral_active_point"
          value={data.referral_active_point}
          onChange={handleChange}
        />
        <SelectComponent
          data={['Single Product', 'All product']}
          label="Referral point source"
          placeholder=""
          name="product_getting_point"
          value={data.product_getting_point}
          onChange={handleChange}
        />
        <Input
          label="Select Starting Date"
          placeholder=""
          name="start_date"
          type="date"
          required
          value={data.start_date}
          onChange={handleChange}
        />
        {
          data.duration === 'Fixed' && (
            <Input
              label="End Date"
              placeholder=""
              name="end_date"
              type="date"
              required
              value={data.end_date}
              onChange={handleChange}
            />
          )
        }
        <Button
          label={isEdit ? 'Update' : 'Create'}
          type="flat"
          btnActionType="submit"
        />
      </form>
    </div>
  )
}